import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const [open, setOpen] = useState(false);
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [kycStatus, setKycStatus] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + "settings/data");
      setWebsiteSettings(response.data);
    } catch (error) {
      if (!navigator.onLine) {
        toast.error("Please check your internet connection.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const checkRole = async () => {
  try {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const res = await axios.get(baseUrl + `me`, { headers });
    setKycStatus(res.data);
    const userRole = res.data.role;
    if (userRole === 'admin') {
      console.log('User is an admin');
    } else {
      console.log('User is not an admin');
    }
  } catch (error) {
    // Log unexpected errors to the console
    console.error("An unexpected error occurred while checking the role:", error);
    
    // Check for unauthorized access
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      toast.info("Session expired or unauthorized access. Please log in again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      // Handle other types of errors
      toast.error("Something went wrong. Please try again later.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  }
};

  
  useEffect(() => {
    const access_token = localStorage.getItem("token");
    if (!access_token) {
      // Redirect to login or show a message
    }
    checkRole();
    fetchData();
  }, []);

  return (
    <>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
   {/*     
<div style={{ width: '100%', textAlign: 'center' }}>
    <div style={{
        backgroundColor: '#dcdcdc', // Lighter silver
        color: '#333', // Darker text for contrast
        fontSize: '9px',
        padding: '8px 0',
        fontWeight: 'bold',
        borderRadius: '5px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        display: 'block',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        background: 'linear-gradient(145deg, #f0f0f0, #dcdcdc)',
    }}>
       🎉 EXCLUSIVE OFFER | COMMISSION: 3% ◉ REFERRAL: 2% FOR ALL GAMES 🎉
    </div>
</div>*/}

          {kycStatus && kycStatus.verified === "unverified" && (
             <div
    className="d-flex align-items-center justify-content-between alert alert-danger show mt-2"
    style={{ width: '94%', margin: '0 auto' }}
>

              <span>
                <b>KYC Pending</b> &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="red">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                </svg>
              </span>
                 <Link to="/Kyc2" style={{ textDecoration: 'none' }}>
      <button
        className="btn btn-danger btn-sm text-capitalize"
        style={{ color: 'white', border: 'none' }}
      >
        <b>Complete here</b>
      </button>
    </Link>
    
            </div>
                  )}

          

          {WebSitesettings && WebSitesettings.CompanyAddress && (
            <div className="mt-2 container position-relative" style={{ maxWidth: '100%' }}>
              <div
                role="alert"
                className="fade d-flex align-items-center justify-content-between alert alert-warning show text-start"
                style={{
                  fontSize: '0.8rem',
                  backgroundColor: 'light',
                   borderRadius: '10px',
                  border: '2px double #776420',
                  boxShadow: '0 0 10px rgba(0,0,0,0.2)'
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="18"
                    height="18"
                    fill="red"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                  </svg>
                  &nbsp; <span style={{ color: '#786322', fontWeight: 'bold' }}>
                    {WebSitesettings.CompanyAddress}</span>
                </span>
              </div>
            </div>
          )}

  
<div className="card mb-1 p-3" style={{ borderRadius: '15px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)', backgroundColor: '#ffffff' }}>
  <div className="d-flex align-items-center justify-content-between games-section-title">
    <h3 style={{ fontWeight: '600', color: '#333', margin: 0 }}>Games</h3>
<a href="https://youtu.be/62ZxW8FXldM?si=9LGmp8m-kph2i_Ub" target="_blank" rel="noreferrer">
  <button style={{ backgroundColor: '#007BFF', color: 'white', padding: '6px 10px', borderRadius: '6px', border: 'none', cursor: 'pointer', fontSize: '12px', display: 'flex', alignItems: 'center', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', transition: 'background-color 0.3s ease' }} onMouseOver={e => e.currentTarget.style.backgroundColor = '#0056b3'} onMouseOut={e => e.currentTarget.style.backgroundColor = '#007BFF'}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-1" style={{ fill: 'white' }}>
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
    </svg>
    &nbsp; Guide
  </button>
</a>
  </div>
</div>
<section className="games-section p-3">
  <div className="games-section-headline mt-2 mb-1" style={{ color: '#2c3e50' }}>
    <div className="games-window">
      <Link className="gameCard-container" to={`/Homepage/Ludo%20Classics`}>
        <div className="live-indicator" style={{ 
          position: 'absolute', 
          top: '10px', 
          left: '10px', 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '5px 10px', 
          borderRadius: '5px',
          fontWeight: 'bold',
          fontSize: '0.8rem',
          zIndex: 1
        }}>
          ◉ LIVE
        </div>
        <picture className="gameCard-image" style={{ position: 'relative' }}>
          <img
            width="100%"
            src="https://i.postimg.cc/X7QsMwJ1/Classic.jpg"
            alt=""
            style={{
              border: '2px double #8E7EF8',
              borderRadius: '5px',
              boxShadow: '0px 0px 10px #8E7EF8'
            }}
          />
        </picture>
      </Link>

      <Link className="gameCard-container" to={`/Homepage/Ludo%20Popular`}>
        <div className="live-indicator" style={{ 
          position: 'absolute', 
          top: '10px', 
          left: '10px', 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '5px 10px', 
          borderRadius: '5px',
          fontWeight: 'bold',
          fontSize: '0.8rem',
          zIndex: 1
        }}>
          ◉ LIVE
        </div>
        <picture className="gameCard-image" style={{ position: 'relative' }}>
          <img
            width="100%"
            src="https://i.postimg.cc/hjsdJ1NH/popular.jpg"
            alt=""
            style={{
              border: '2px double #8E7EF8',
              borderRadius: '5px',
              boxShadow: '0px 0px 10px #8E7EF8'
            }}
          />
        </picture>
      </Link>


           {/*      <Link className="gameCard-container" to={``} onClick={() => toast.info("This game is coming soon!", { position: toast.POSITION.BOTTOM_RIGHT })}>
                <br />
               <div className="gameCard-image-container" style={{ position: "relative" }}>
              <div className="overlay"></div>
                  <img
                    className="image"
                    src="https://i.postimg.cc/hjsdJ1NH/popular.jpg"
                    alt=""
                    style={{ 
                    border: '2px double #8E7EF8',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px #fff'
                          }}
                  />
             <div className="coming-soon">
            <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMING SOON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                 </div>
                </div>
                </Link>*/}
                
               
                  <Link className="gameCard-container" to={``} onClick={() => toast.info("This game is coming soon!", { position: toast.POSITION.BOTTOM_RIGHT })}>
                 <br />
               <div className="gameCard-image-container" style={{ position: "relative" }}>
              <div className="overlay"></div>
                <img
    className="image"
    src="https://i.postimg.cc/tg4BmqDs/Picsart-24-08-08-04-25-40-493.jpg"
    alt=""
    style={{ 
        border: '2px double #8E7EF8',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px #fff'
    }}
/>  
             <div className="coming-soon">
            <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMING SOON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                 </div>
                </div>
                </Link>
                
                 <Link className="gameCard-container" to={``} onClick={() => toast.info("This game is coming soon!", { position: toast.POSITION.BOTTOM_RIGHT })}>
                <br />
               <div className="gameCard-image-container" style={{ position: "relative" }}>
              <div className="overlay"></div>
                  <img
                    className="image"
                    src="https://i.postimg.cc/T3Lwjgv1/Picsart-24-08-08-04-27-38-135.jpg"
                    alt=""
                    style={{ 
                    border: '2px double #8E7EF8',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px #fff'
                          }}
                  />
             <div className="coming-soon">
            <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMING SOON &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                 </div>
                </div>
                </Link> 
               
              </div>
            </div>
          </section>
<section className="footer" style={{ backgroundColor: "#f8f9fa", padding: "20px", borderTop: "1px solid #e0e0e0" }}>
  <a
    className="d-flex align-items-center px-3 py-3"
    href="#!"
    style={{ textDecoration: "none", color: "#007bff", padding: "10px 0", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
    onClick={() => setOpen(!open)}
    aria-controls="footer-collapse-text"
    aria-expanded={open}
  >
    <picture className="icon">
      <img
        src="https://i.postimg.cc/0NF15k2p/skillclash.png"
        width="150px"
        height="40px"
        alt="SkillClash Logo"
        style={{ width: "140px", height: "40px" }}
      />
    </picture>
    <span
      style={{
        color: "#6c757d",
        fontSize: "1em",
        fontWeight: 400,
        marginLeft: "15px"
      }}
      className={!open ? "d-block" : "d-none"}
    >
      Help & Support
    </span>
    {open ? (
      <i
        className="mdi mdi-chevron-up ml-auto"
        style={{ fontSize: "1.7em", color: "#6c757d" }}
      ></i>
    ) : (
      <i
        className="mdi mdi-chevron-down ml-auto"
        style={{ fontSize: "1.7em", color: "#6c757d" }}
      ></i>
    )}
  </a>
 <Collapse in={open}>
  <div id="footer-collapse-text" className="px-3 overflow-hidden">
    <div className="row footer-links" style={{ borderTop: "1px solid #e0e0e0", paddingTop: "10px" }}>
      {/* First Row */}
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/term-condition" style={{ color: "#007bff", textDecoration: "none" }}>
            Terms &amp; Conditions
          </Link>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/PrivacyPolicy" style={{ color: "#007bff", textDecoration: "none" }}>
            Privacy Policy
          </Link>
        </div>
      </div>

      {/* Second Row */}
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/RefundPolicy" style={{ color: "#007bff", textDecoration: "none" }}>
            Refund/Cancellation Policy
          </Link>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/contact-us" style={{ color: "#007bff", textDecoration: "none" }}>
            Contact Us
          </Link>
        </div>
      </div>

      {/* Third Row */}
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/responsible-gaming" style={{ color: "#007bff", textDecoration: "none" }}>
            Responsible Gaming
          </Link>
        </div>
      </div>
      <div className="col-6 mb-3">
        <div style={{ padding: "10px", borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
          <Link to="/Rules" style={{ color: "#007bff", textDecoration: "none" }}>
            Game Rules
          </Link>
        </div>
      </div>
    </div>
  </div>
</Collapse>
</section>
          <ToastContainer
            style={{ marginBottom: '25px' }}
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
    </>
  );
}
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import '../css/layout.css';
import '../css/login.css';
import loginss from "./ss.png";

const Login = () => {
  const history = useHistory();

  const [Phone, setPhone] = useState('');
  const [twofactor_code, settwofactor_code] = useState('');
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const [referral, setReferral] = useState(useLocation().pathname.split("/")[2]);
  const [timer, setTimer] = useState(35);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');
  let timerInterval;

  useEffect(() => {
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    showInfo('Welcome to Skill Clash!');
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setButtonText('');

    if (!Phone) {
      showError('Please enter your phone number');
      setLoading(false);
      setButtonText('Continue');
    } else if (Phone.length !== 10) {
      showError('Please enter a valid phone number');
      setLoading(false);
      setButtonText('Continue');
    } else {
      try {
        const response = await axios.post(`https://backend.skillclash.in/login`, {
          Phone,
          referral
        });

        if (response.data.status === 101) {
          showError(response.data.msg);
          setLoading(false);
          setButtonText('Continue');
        } else if (response.data.status === 200) {
          setOtp(true);
          setOtpSent(true);
          setSecretCode(response.data.secret);
          startTimer();
          showInfo('OTP sent to Monile number');
          console.log(response.data);
          setLoading(false);
          setButtonText('Continue'); // Set button text back to 'Continue' after spinner
        }
      } catch (error) {
        showError('Something went wrong');
        setLoading(false);
        setButtonText('Continue');
      }
    }
  }

  const startTimer = () => {
    let seconds = 35;

    timerInterval = setInterval(() => {
      if (seconds > 0) {
        setTimer(seconds);
        seconds--;
      } else {
        clearInterval(timerInterval);
        setTimer(0);
        setOtpSent(false);
      }
    }, 1000);
  }

  const resendOTP = async () => {
    if (!otpSent) {
      try {
        const response = await axios.post(`https://backend.skillclash.in/login`, {
          Phone,
          referral
        });

        if (response.data.status === 200) {
          setOtpSent(true);
          startTimer();
        }
      } catch (error) {
        showError('Failed to resend OTP');
      }
    } else {
      showInfo('OTP already sent. Please wait for the timer to expire.');
    }
  }

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (!Phone) {
      showError('Please enter your phone number');
    } else {
      try {
        setLoadingVerify(true); // Set loadingVerify to true when starting verification
        const response = await axios.post(`https://backend.skillclash.in/login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode
        });

        if (response.data.status === 101) {
          showError(response.data.msg);
        } else if (response.data.status === 200) {
          const token = response.data.token;
          localStorage.setItem("token", token);
          window.location.reload(true);
          setTimeout(() => {
            history.push("/Games");
          }, 1000);
          showSuccess('Login successful!');
        } else {
          showError('Something went wrong!');
        }
      } catch (error) {
        showError('Something went wrong!');
      } finally {
        setLoadingVerify(false); // Set loadingVerify back to false after verification
      }
    }
  }

  const showError = (message) => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    });
  }

  const showInfo = (message) => {
    toast.info(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    });
  }

  const showSuccess = (message) => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    });
  }

  return (

    <>
<div className='leftContainer' 
     style={{ minHeight: '100vh', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '0px' }}>
  
  {/* Top card with SkillClash message */}
  <div className="top-card text-center mb-4 shadow-lg rounded" 
       style={{ 
         backgroundColor: '#f7f7f7', 
         color: '#333333', 
         borderRadius: '10px', 
         padding: '10px 20px', 
         border: '2px solid #e0e0e0', 
         width: '380px', 
         fontWeight: 'bold', 
         fontSize: '18px', 
         marginBottom: '20px', 
         display: 'flex', 
         alignItems: 'center', 
         justifyContent: 'center' 
       }}>
    <img
      src="https://i.postimg.cc/YqKfFvWq/1722080888503.png"
      className="snip-img"
      alt="SkillClash | No Luck Only Skill"
      width="20px"
      style={{ marginRight: '10px' }}
    />
    SkillClash | No Luck Only Skill
  </div>

  {/* Login Card */}
  <div className="main-area shadow-lg rounded" 
       style={{ 
         width: '380px', 
         backgroundColor: '#ffffff', 
         borderRadius: '20px', 
         padding: '30px', 
         boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', 
         border: '2px solid #e0e0e0' 
       }}>
    
    <div className="text-center mb-4">
      <h2 className="text-dark">Sign in or Sign up</h2>
    </div>
    
       <div className="card p-2 mb-3" style={{ borderRadius: '5px', backgroundColor: '#ffffff', border: '1px solid #e0e0e0' }}>
        <div className="input-group mb-1">
          <div className="input-group-prepend">
            <div className="input-group-text" style={{ width: '50px', backgroundColor: '#ffffff', border: '1px solid #e0e0e0', borderRadius: '5px 0 0 5px', color: '#333333' }}>+91</div>
          </div>
          <input className="form-control" name="mobile" type="tel" placeholder="Mobile number"
            onChange={(e) => setPhone(e.target.value)}
            style={{ borderRadius: "0 5px 5px 0", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', color: '#333333', marginBottom: '5px' }}
          />
        </div>

      </div>

      {otp && (
        <div className="card p-2 mb-3 slide-in-left" style={{ borderRadius: '5px', backgroundColor: '#ffffff', border: '1px solid #e0e0e0' }}>
          <div className="input-group mb-1">
            <div className="input-group-prepend">
              <div className="input-group-text" style={{ width: '50px', backgroundColor: '#ffffff', border: '1px solid #e0e0e0', borderRadius: '5px 0 0 5px', color: '#333333' }}>OTP</div>
            </div>
            <input className="form-control" name="password" type="tel" placeholder="Enter OTP"
              onChange={(e) => settwofactor_code(e.target.value)}
              style={{ borderRadius: "0 5px 5px 0", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', color: '#333333' }} />
          </div>
        </div>
      )}
      
    {!loading && !otp && (
      <button
        className="Login-button btn w-100"
        onClick={(e) => {
          handleClick(e);
          e.currentTarget.style.transform = 'scale(0.98)';
        }}
        style={{
          borderRadius: '5px',
          padding: '18px 0',
          fontSize: '20px',
          backgroundColor: '#e0e0e0',
          border: 'none',
          color: '#333333',
          fontWeight: 'bold',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, transform 0.2s',
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#cccccc')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
      >
        <span style={{ color: '#333', fontWeight: 'bold', fontSize: '15px' }}> {buttonText} </span>
      </button>
    )}
    
    {!loading && otp && (
      <button
        className="Login-button btn w-100"
        onClick={(e) => {
          verifyOtp(e);
          e.currentTarget.style.transform = 'scale(0.98)';
        }}
        style={{
          borderRadius: '5px',
          padding: '18px 0',
          fontSize: '20px',
          backgroundColor: '#4caf50',
          border: 'none',
          color: '#ffffff',
          fontWeight: 'bold',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, transform 0.2s',
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#45a049')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4caf50')}
      >
        {loadingVerify ? (
          <div className="spinner-border text-light" role="status"></div>
        ) : (
          <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '15px' }}>Verify OTP</span>
        )}
      </button>
    )}
    
    {loading && (
      <button
        className="Login-button btn w-100"
        disabled
        style={{
          borderRadius: '10px',
          padding: '18px 0',
          fontSize: '20px',
          backgroundColor: '#b0bec5',
          border: 'none',
          color: '#ffffff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="spinner-border text-light" role="status"></div>
      </button>
    )}
    
    {otp && timer > 0 && <div className="text-center mt-3 text-dark">Didn't receive OTP? Retry in ( {timer} ) seconds</div>}
    {otp && timer === 0 && (
      <div className="text-center mt-3">
        <a href="#resend-otp" onClick={resendOTP} style={{ color: '#333333', textDecoration: 'underline' }}>Resend OTP</a>
      </div>
    )}
    
    <br />
    <div className="form-check text-dark" style={{ fontSize: '12px' }}>
      <input 
        type="checkbox" 
        className="form-check-input" 
        id="agreeCheckbox" 
        style={{ backgroundColor: '#e0e0e0', borderColor: '#333333' }} 
      />
      <label className="form-check-label" htmlFor="agreeCheckbox">
        &nbsp;&nbsp;&nbsp;I agree to the <Link to="/term-condition" style={{ color: '#333333' }}>Terms of Use</Link> and 
        <Link to="/PrivacyPolicy" style={{ color: '#333333' }}>Privacy Policy</Link>. By continuing, I agree that SkillClash may store and process my data, and that I am 18+ and not playing from restricted states.
      </label>
    </div>
    
  </div>
</div>

      <ToastContainer
        style={{ marginTop: '1px' }}
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default Login; 